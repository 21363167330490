.mainContent {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 22px;
    height: calc(100vh - 140px); /* Adjust the value based on the heights of the top navigation bar and any other elements that might affect the height */
    overflow: auto;
    align-items: center;
  }

  .mainContent::-webkit-scrollbar {
    width: 9px;
  }
  .mainContent::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  } 
  .mainContent::-webkit-scrollbar-thumb {
    background-color: #3C96D4;
    border-radius: 5px;
  }

  .content {
    display: flex;
    align-items: left;
    width: 100%;
  }

  .title {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 28px;
    margin-top: 27px;
    width: 897px;
  }

  .title2 {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
  }

  .title3 {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
    color: #D9D9D9;
  }

  .approvalsHolidayManagment {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    font-family: 'PT Sans', sans-serif;
    width: 965px;
  }

  .daysLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 20px;
  }

  .holidayDaysNum {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 64px;
    margin-right: 15px;
    font-family: 'PT Sans', sans-serif;
  }

  .holidayDaysText {
    word-wrap: break-word;
    font-family: 'Baloo Bhai', cursive;
    font-size: 20px;
  }

  .bookedLeaveBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 2px solid #ECEEF6;
    width: 965px;
    height: 166px; /* Adjust this value to accommodate up to 4 rows without showing the scrollbar */
    border-radius: 6px;
    position: relative; /* Add this to create a new positioning context */
    overflow-y: auto; /* Add this to enable a scrollbar when the content exceeds the container's height */
  }
  
  .bookedLeaveBox table {
    width: 100%;
    height: 100%; /* Set the table's height to 100% */
    border-collapse: collapse;
    font-family: 'PT Sans', sans-serif;
  }

  .bookedLeaveBox th {
    font-weight: normal;
    font-size: 12px; /* Set the font size of the column names */
    color: #949494; /* Set the color of the column names */
    padding: 8px;
    text-align: center; /* Center the values in the columns */
    border-bottom: 1px solid #ECEEF6;
  }
  
  .bookedLeaveBox td {
    font-size: 14px;
  }

  .bookedLeaveBox th,
  .bookedLeaveBox td {
    padding: 8px;
    text-align: center; /* Center the values in the columns */
  }

  .bookedLeaveBox thead th {
    position: sticky; /* make the table heads sticky */
    top: 0px; 
    background-color: #FFFFFF;
    border-bottom: 1px solid #ECEEF6; /* Add a 1px line between the column names and the rows */
  }
  
  .bookedLeaveBox tr:last-child td {
    border-bottom: none;
  }
  
  
  .bookedLeaveBox::-webkit-scrollbar {
    width: 7px;
  }
  
  .bookedLeaveBox::-webkit-scrollbar-track {
    background-color: #ECEEF6;
    border-radius: 10px;
  }
  
  .bookedLeaveBox::-webkit-scrollbar-thumb {
    background-color: #3C96D4;
    border-radius: 10px;
  }
  
  .bookedLeaveBox::-webkit-scrollbar-thumb:hover {
    background-color: #7A7A7A;
  }

  .statusPending {
    background-color: #F37021;
    color: #FFFFFF;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    width: 92px;
    height: 25px;
    text-align: center;
    justify-content: center;
    display: flex; /* Add this to use flexbox */
    align-items: center;
  }

  .statusApproved {
    background-color: #0B8A00;
    color: #FFFFFF;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    width: 92px;
    height: 25px;
    text-align: center;
    justify-content: center;
    display: flex; /* Add this to use flexbox */
    align-items: center;
  }

  .bookingLeave {
    margin-left: 25px;
    margin-top: 28px;
    background-color: #FFFFFF;
    border: 2px solid #ECEEF6;
    width: 333px;
    height: 283px;
    border-radius: 6px;
  }

  .companyHolidayCalendar {
    flex-grow: 1;
    background-color: #FFFFFF;
    border: 2px solid #ECEEF6;
    border-radius: 6px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .bookingLeaveItem {
    margin-bottom: 10px;
  }

  .approveButton {
    padding-right: 32px;
      width: 36px;
      height: 25px;
      margin-right: 10px;
      font-size: 13px;
      font-weight: bold;
      color: #FFFFFF;
      background-color: #3C96D4;
      border: none;
      border-radius: 6px;
      cursor: pointer;
  }

.rejectButton {
    width: 36px;
    height: 25px;
    margin-right: 10px;
    font-size: 13px;
    font-weight: bold;
    color: #3C96D4;
    background-color: #FFFFFF;
    border: 2px solid #3C96D4;
    border-radius: 6px;
    cursor: pointer;
}

.rejectingLeaveCommentBox {
  margin-left: 24px;
  margin-right: 24px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  margin-top: 20px;
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
}

.rejectingLeaveCommentBox:focus {
  border: 1px solid #c4c4c4;
}

.rejectingLeaveCommentBox::placeholder {
  color: #242424;
}

.ProfileHolidayOverlay {
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  position: fixed; /* Cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000; /* Ensure it's above everything else */
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

.bookingLeaveComment {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
}

.nonBookingLeaveComment{
  padding-left: 4px;
  padding-right: 4px;
  font-size: 14px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
  color: #D9D9D9;
}

.ProfileHolidayResponseBox {
  background-color: #ffffff;
  min-width: 160px;
  max-width: 500px;
  min-height: 140px;
  max-height: 300px;
  border-radius: 20px;
  border: solid 2px #3C96D4;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ProfileHolidayButton {
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #3C96D4;
  border: none;
  border-radius: 6px;
  width: 37px;
  height: 28px;
  cursor: pointer;
  margin-bottom: 20px;
}

.ProfileHolidayButton:active {
  background-color: #ccc; /* Change the background color */
}

.ProfileHolidayButton:hover {
  background-color: #165b8a; /* Change the background color */
}

.LeaveAdjustments {
  padding: 25px; /* Change the background color */
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 2px #ECEEF6;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.textbox {
  font-size: 13px;
  font-weight: 300;
}
.textbox:hover {
  cursor: pointer;
  color: #3C96D4;
}