.dropdown {
  position: relative;
  display: inline-block;
  user-select: none;
  z-index: 5;
  width: 100%; /* Add this */
}

.dropdownSelected {
  height: 15px;
  width: calc(100% - 8px);
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 6px;
  background-color: #fff;
  border: 1px solid #3C96D4;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  z-index: 5;
}

.dropdownSelectedText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;
}

.noOverflow {
  overflow: visible;
  min-width: 100%;
}

.multiOverflow {
  overflow: hidden;
  width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.multiOverflow, .noOverflow {
  min-width: 50px; /* Adjust as needed */
  min-height: 20px; /* Adjust as needed */
}

.dropdownOptions {
  max-height: 160px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: #fff;
  border-top: none;
  border-radius: 10px;
  z-index: 5;
  overflow-x: hidden;
  border: 1px solid #3C96D4;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.dropdownOption {
  position: relative; /* Add this to position the square */
  align-items: center;
  height: 15px;
  width: calc(100% - 11px);
  padding-left: 5px;
  margin-left: 3px;
  padding-top: 1px;
  margin-top: 1px;
  padding-bottom: 5px;
  margin-bottom: 1px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  z-index: 100;
}

.dropdownOption:hover {
  background-color: #EAF2F9;
  font-weight: bold;
  z-index: 5;
}
.dropdownOptions::-webkit-scrollbar {
  width: 4px;
}

.dropdownOptions::-webkit-scrollbar-track {
  background-color: transparent;
}

.dropdownOptions::-webkit-scrollbar-thumb {
  background-color: #3C96D4; 
  border-radius: 4px;
}
.dropdown::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  border: solid;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  padding: 2px;
  pointer-events: none;
  border-color: #333;
  transform: translateY(-50%) rotate(45deg);
}
.dropdownDisabled .dropdownSelected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: lightgray;
  cursor: not-allowed;
}

.dropdownDisabled .dropdownOptions {
  background-color: lightgray;
  cursor: not-allowed;
}

.selectedSquare {
  position: absolute;
  left: 5px;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: #3C96D4;
  border: 1px solid #3C96D4;
  transform: translateY(-50%);
}

.unselectedSquare {
  position: absolute;
  left: 5px;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border: 1px solid grey;
  transform: translateY(-50%);
}

.multiTextShift {
  margin-left: 20px;
}

.dropdownAbsolute {
  position: absolute;
  width: 500px;
  z-index: 1000;  /* Adjust z-index as needed */
}

.dropdownOptionsAbsolute {
  position: absolute;
  width: 100%;
  /* margin-top: -15px; */
  z-index: 1000;  /* Adjust z-index as needed */
}

.placeholderColor {
  color: #000000;
}

.invisibleInput {
  border: none; /* Removes border */
  outline: none; /* Removes the outline */
  background-color: transparent; /* Sets background to transparent */
  color: inherit; /* Text color inherited from parent */
  width: 100%; /* Full width */
  padding: 4px; /* Optional: for better text alignment */
  
  font-size: 13px;
  font-weight: bold;
  font-family: 'PT Sans';

  position: absolute;
  top: 0px; /* Shifts the input up by 5 pixels */
  left: 5px;
}

.invisibleInput::placeholder {
  color: #000000; /* Light grey placeholder text */
}

.invisibleInput:focus {
  box-shadow: none; /* Removes any focus shadow */
}

.errorState{
  border:1px solid red;
}