.SliderSwitch {
  position: relative;
  display: inline-block;
  user-select: none;
  width: 40px; /* Adjusted width */
  height: 20px; 
  background-color: #fff;
  border: 1px solid #3C96D4;
  border-radius: 12px; /* Adjusted for better rounded corners */
  cursor: pointer;
  z-index: 5;
}

.SliderSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  top: 1px; /* Small padding to show border */
  left: 1px; 
  width: 18px; /* Adjusted width */
  height: 18px; /* Adjusted height */
  background-color: #ccc;
  border-radius: 10px; /* Adjusted for better rounded corners */
  transition: 0.4s;
  z-index: 4;
}

input:checked + .Slider {
  background-color: #3C96D4;
  left: 20px; /* Adjusted movement for slider */
}

.disabled .Slider {
  background-color: #ccc; /* Grey background for the slider */
}

.disabled {
  cursor: not-allowed;
  background-color: #e0e0e0; /* Light grey for the switch background */
}
